import React from "react"
import Layout from "../components/layout"
import SEOHeader from "../components/seo-header"
import "../styles/style.scss"

export default function GetTheApp() {
  return (
    <Layout>
      <SEOHeader
        title="Get the GoFloaters App"
        description="GoFloaters is a platform that helps you find work and meeting spaces that can be booked instantly."
        socialURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
        pinterest="true"
      ></SEOHeader>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img
              src="https://assets.gofloaters.com/teams/team-app-screen.jpg"
              className="img-responsive"
            ></img>
          </div>
          <div className="col-md-8">
            <h1 className="text-center" style={{ paddingTop: "40px" }}>
              Get The GoFloaters App
            </h1>
            <p className="text-center">
              We’re consistently rated above 4.5 stars on Play Store & App Store
              for quality workspaces and proactive customer support. See for
              yourself, download now.
            </p>
            <p className="text-center padding-60">
              <a
                href="https://play.google.com/store/apps/details?id=com.gofloaters.app&referrer=utm_source%3Dwebsite%26utm_medium%3Dhomepage%26utm_term%3Dcoworking%26utm_content%3Dhowitworks%26utm_campaign%3Dwebsite"
                target="_blank"
                title="Gofloaters @ Google Play Store"
                rel="noopener noreferrer nofollow"
              >
                <img
                  src="https://assets.gofloaters.com/google-play-gofloaters-download.png"
                  alt="Gofloaters App @ Google Play Store"
                  width="150"
                />{" "}
              </a>
              &nbsp;
              <a
                href="https://itunes.apple.com/in/app/gofloaters/id1446010118?mt=8&uo=4&at=11l6hc&app=itunes&ct=fnd"
                rel="noopener noreferrer nofollow"
                target="_blank"
                title="Gofloaters App @ Apple Store"
              >
                <img
                  src="https://assets.gofloaters.com/gofloater-apple-store.png"
                  alt="Gofloaters App @ Apple Store"
                  width="150"
                />{" "}
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center" style={{ paddingTop: "40px" }}>
              GoFloaters WorqFlexi App for Slack
            </h1>
            <br></br>
          </div>
          <div className="col-md-8">
            <p>
              GoFloaters helps you and your teams gain instant access to work
              and meeting spaces across India.
              <br />
              <br /> 🗺 <b>Widest network of office spaces</b>
              <br /> GoFloaters offers 2100 spaces from 300 brands of coworking
              across 43 Indian cities. We the the widest network of ondemand
              work and meeting spaces.
              <br />
              <br /> 🎟 <b>On-demand and instant bookings</b>
              <br /> We specialise in on-demand bookings. Book a desk or a
              meeting room and get instant confirmations.
              <br />
              <br /> 💳 <b>Pay-per-use pricing</b>
              <br /> You pay for the space that you have used. Nothing more and
              nothing less. Say no to capital expenses on office real estate.
              <br />
            </p>
          </div>
          <div className="col-md-4">
            <img
              src="https://assets.gofloaters.com/homenew/gofloaters-slack.JPG"
              className="img-responsive"
            ></img>
          </div>
          <div className="col-md-12">
            <br></br>
            <p>
              🚫 <b>No contracts or deposits</b>
              <br />
              No need to sign any contracts with any coworking space. No need to
              pay deposits and lock in your capital. We have taken care of all
              the partnerships for you. You just signup with us and start
              booking instantly. <br />
              <br />
              🤝 <b>Foster collaboration</b>
              <br />
              Team members can see where the rest of their teams are working out
              of. Meeting rooms can be booked for in-person collaboration. Team
              members can tag others while book and notify them that they are
              going to be at the office. <br />
              <br />
              👨‍💼 <b>Know who and which teams are using</b>
              <br />
              Understand how your teams are using the office. Understand which
              team needs more in-person collaboration and which employees need
              access to workspaces near their home. <br />
              <br />
              📈 Take data driven decisions
              <br />
              Understand the data patterns emerging from the bookings. Answer
              questions like :<br />
              <ul>
                <li>What are the favaorites days for work</li>
                <li>When do people want to meet in-person</li>
                <li>How is the usage by one team compare against the other</li>
                <li>How is the usage by one city compare against the other</li>
              </ul>
              Make data driven decisions to tweak your hybrid remote approach.
            </p>
          </div>
          <div className="col-md-12">
            <br />
            <br />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row aligner">
          <div className="col-md-8">
            <h2>Installation and Usage Instructions</h2>
            <p>
              GoFloaters app for Slack is for WorqFlexi customers of GoFloaters
              only. Before you install this app kindly ensure that your
              organization has a valid WorqFlexi acocunt with GoFloaters.
            </p>
            <ul>
              <li>
                Ask you Slack administrator to add GoFloaters app for Slack to
                your workspace
              </li>
              <li>
                Launch GoFloaters app for Slack from the workspace to check the
                spaces that have been approved for you to reserve
              </li>
              <li>
                Change the city if you have to get the spaces in that city
              </li>
              <li>Make your reservation by clicking the "Book Now" button</li>
              <li>Fill out the form to reserve your space</li>
            </ul>
          </div>
          <div className="col-md-4 text-center">
            <a href="https://slack.com/oauth/v2/authorize?client_id=4090895728595.4093487176788&scope=app_mentions:read,users:read,users:read.email&user_scope=chat:write">
              <img
                alt="Add to Slack"
                height="40"
                width="139"
                src="https://platform.slack-edge.com/img/add_to_slack.png"
                srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
              />
            </a>
          </div>
          <div className="col-md-12">
            <br />
            <br />
          </div>
        </div>
      </div>
    </Layout>
  )
}
